import React, {useEffect, useState} from "react";
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {extractErrorMessage} from "../../apis/utils";
import * as yup from "yup";
import {Form as FormikForm} from "react-formik-ui";
import {Formik} from "formik";
import FormField from "./FormField";
import {useUsers} from "../../hooks/user-hook";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(1)
    .max(256)
    .required("First name is required."),
  lastName: yup
    .string()
    .min(1)
    .max(25)
    .required("Last name is required."),
  email: yup
    .string()
    .email()
    .required("A valid email address is required."),
});

const UserForm = () => {
  const [open, setOpen] = useState(false);
  const { createState, createUser, addUser } = useUsers();

  useEffect(() => {
    if (createState.successful) {
      addUser(createState.data);
      setOpen(false);
    }
  }, [createState]);

  const handleUserCreate = (data) => {
    createUser({
      data: { ...data },
    });
  };

  const renderError = () => {
    if (createState.error) {
      let errMsg = extractErrorMessage(createState.error);
      if (!errMsg) {
        errMsg = `Unknown error creating user:\n${JSON.stringify(
          createState.error
        )}.`;
      }
      return <Message error content={errMsg} />;
    }
    return null;
  };

  return (
    <>
      <Button icon="user" content="Create User" onClick={setOpen} />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        validationSchema={schema}
        validateOnChange
        validateOnBlur
        onSubmit={handleUserCreate}
        render={({
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          errors,
          touched,
          values,
        }) => (
          <Modal
            open={open}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            size="small"
          >
            <Modal.Header>
              <span>Create User</span>
            </Modal.Header>
            <Modal.Content>
              {renderError()}
              <Form
                as={FormikForm}
                onSubmit={handleSubmit}
                key="createUserForm"
                loading={createState.loading}
                error={createState.error}
              >
                <Form.Group>
                  <FormField
                    name="firstName"
                    errors={errors.firstName}
                    touched={touched.firstName}
                    placeholder="First Name"
                    label="First Name:"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.firstName}
                    position="right center"
                  />
                </Form.Group>
                <Form.Group>
                  <FormField
                    name="lastName"
                    errors={errors.lastName}
                    touched={touched.lastName}
                    placeholder="Last Name"
                    label="Last Name:"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.lastName}
                    position="right center"
                  />
                </Form.Group>
                <Form.Group>
                  <FormField
                    name="email"
                    errors={errors.email}
                    touched={touched.email}
                    placeholder="Email"
                    label="Email:"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.email}
                    position="right center"
                  />
                </Form.Group>
                <Modal.Actions className="floated right">
                  <Button
                    content="Create User"
                    icon="check"
                    type="submit"
                    positive
                  />
                  <Button
                    content="Cancel"
                    icon="cancel"
                    onClick={() => {
                      setOpen(false);
                      resetForm();
                    }}
                    type="reset"
                  />
                </Modal.Actions>
              </Form>
            </Modal.Content>
          </Modal>
        )}
      />
    </>
  );
};

export default UserForm;
