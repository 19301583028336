import React, {useEffect} from "react";
import {Button, Dimmer, Header, Loader, Message, Segment,} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {format, utcToZonedTime} from "date-fns-tz";
import {useRoom} from "../../hooks/room-hook";
import useSites from "../../hooks/use-sites";
import {capitalizeWords, includes} from "../../apis/utils";

export default () => {
  const {
    // selectedRoom,
    // setSelectedRoom,
    selectRoom,
    deselectRoom,
    fetchRoomsState,
    fetchRooms,
    roomsState,
    setRooms,
    unsetRooms,
  } = useRoom();

  const { siteState } = useSites();
  const timeZone = siteState.activeSite
    ? siteState.activeSite.timeZone
    : "America/New_York";

  const { data, success, error, loading } = fetchRoomsState;

  // TODO: update state in line.
  useEffect(() => {
    fetchRooms();
    console.log(`${JSON.stringify(fetchRoomsState.data, null, 2)}`);
  }, [fetchRooms]);

  useEffect(() => {
    if (fetchRoomsState.data) {
      setRooms(fetchRoomsState.data.rooms);
    }
  }, [fetchRoomsState]);

  useEffect(() => {
    deselectRoom();
    unsetRooms();
    fetchRooms();
  }, [siteState.activeSite]);

  const handleSelectRoom = (event, { id }) => {
    // if (data && data.rooms) {
    //   const singleRoom = data.rooms.filter((a) => a.roomId === id);
    //   if (singleRoom && singleRoom.length > 0) {
    //     selectRoom(singleRoom[0]);
    //   }
    // }
    if (roomsState.rooms) {
      const singleRoom = roomsState.rooms.filter((a) => a.roomId === id);
      if (singleRoom && singleRoom.length > 0) {
        selectRoom(singleRoom[0]);
      }
    }
  };

  if (error) {
    return (
      <Segment>
        <Message
          error
          header={`Error loading room data: ${error.name}`}
          content={error.message}
        />
        Error:
        {JSON.stringify(error, null, 2)}
      </Segment>
    );
  }

  return (
    <Segment>
      <Header content="Rooms" icon={{ name: "hotel", color: "grey" }} />
      <Button
        icon="refresh"
        onClick={() => {
          fetchRooms();
        }}
        primary
        fluid
        disabled={loading || !data}
        attached="top"
      />

      {loading || !data ? (
        <Segment style={{ height: "364px" }}>
          <Dimmer active inverted style={{ height: "364px" }}>
            <Loader inverted active>
              Loading
            </Loader>
          </Dimmer>
        </Segment>
      ) : (
        <ReactTable
          // data={data.rooms}
          data={roomsState.rooms}
          columns={[
            {
              columns: [
                {
                  Header: "",

                  Cell: (row) => (
                    <div>
                      <Button
                        icon="caret right"
                        onClick={handleSelectRoom}
                        size="mini"
                        id={row.original.roomId}
                      />
                    </div>
                  ),
                  filterable: false,
                  sortable: false,
                  width: 40,
                },
                {
                  Header: "Room",
                  accessor: "room",
                  width: 120,
                  headerStyle: { textAlign: "left" },
                  filterMethod: (filter, row) => {
                    return includes(row[filter.id], filter.value);
                  },
                },
                {
                  Header: "Channel Lineup",
                  accessor: "channelMap",
                  width: 125,
                  headerStyle: { textAlign: "left" },
                  filterMethod: (filter, row) => {
                    return includes(row[filter.id], filter.value);
                  },
                },

                {
                  Header: "Building",
                  accessor: "building",
                  width: 75,
                  headerStyle: { textAlign: "left" },
                  filterMethod: (filter, row) => {
                    return includes(row[filter.id], filter.value);
                  },
                },
                {
                  Header: "Room Type",
                  id: "roomType",
                  accessor: (rowValue) => {
                    try {
                      if (rowValue.roomType) {
                        return capitalizeWords(rowValue.roomType);
                      }
                    } catch (e) {
                      console.log(e);
                    }
                    return "";
                  },
                  headerStyle: { textAlign: "left" },
                  filterMethod: (filter, row) => {
                    return includes(row[filter.id], filter.value);
                  },
                  // width: 400
                },
                {
                  Header: "Scheduled Reset",
                  id: "resetTime",
                  accessor: (rowValue) => {
                    try {
                      if (rowValue.resetTime) {
                        const zonedDate = utcToZonedTime(
                          rowValue.resetTime,
                          timeZone
                        );
                        const formattedDate = format(
                          zonedDate,
                          "MM/dd/yyyy hh:mm a zzz",
                          { timeZone }
                        );
                        return formattedDate;
                      }
                      return "Unscheduled";
                    } catch (e) {
                      console.log(e);
                    }
                    return "Unknown";
                  },
                  headerStyle: { textAlign: "left" },
                  filterMethod: (filter, row) => {
                    return includes(row[filter.id], filter.value);
                  },
                },
              ],
            },
          ]}
          className="-striped -highlight"
          filterable
          defaultPageSize={6}
          defaultSorted={[
            {
              id: "room",
              desc: false,
            },
          ]}
        />
      )}
    </Segment>
  );
};
