import {useContext, useEffect, useReducer} from "react";
import {AuthContext} from "../contexts/auth-context";
import defaultLogo from "../assets/default_logo.png";

import {initialState, reducer, useSecureApi,} from "./secure-api-hook-dispatch";

import {
  deselectSite,
  selectSite,
  selectSiteByPropertyId,
  setSites,
  useSiteDispatch,
  useSiteState,
} from "../contexts/site-context";

const useSites = () => {
  const state = useSiteState();
  const dispatch = useSiteDispatch();
  const { authenticated, setPropertyId } = useContext(AuthContext);
  const [fetchSitesState, fetchDispatch] = useReducer(reducer, initialState);
  const [fetchSites] = useSecureApi("", "get", true, fetchDispatch, false);

  useEffect(() => {
    if (!authenticated) {
      // setLogo(null);
    } else {
      fetchSites();
    }
  }, [authenticated]);

  useEffect(() => {
    if (fetchSitesState.successful) {
      setSites(dispatch, fetchSitesState.data);
    }
  }, [fetchSitesState]);

  useEffect(() => {
    if (state.activeSite) {
      setPropertyId(state.activeSite.propertyId);
    }
  }, [state.activeSite]);

  return {
    fetchSitesState,
    fetchSites,
    selectSite: (site) => {
      selectSite(dispatch, site);
    },
    deselectSite: () => {
      deselectSite(dispatch);
    },
    selectSiteByPropertyId: (propertyId) => {
      selectSiteByPropertyId(dispatch, propertyId);
    },
    siteState: { ...state },
    defaultLogo,
  };
};

export default useSites;
