import React, {useEffect, useState} from "react";
import OktaAuth from "@okta/okta-auth-js";
import config from "../okta.config";
import history from "../routing/history";
import axios from "axios";

const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
  let prevAuth = window.localStorage.getItem("authenticated") || false;
  let prevUser = window.localStorage.getItem("user") || null;
  let prevAdmin = window.localStorage.getItem("admin") || false;
  let prevAccessToken = window.localStorage.getItem("accessToken") || null;
  let prevPropertyId = window.localStorage.getItem("propertyId") || null;

  // Production build raises parse errors when "undefined" is parsed.
  // Still investigating.
  try {
    if (prevAuth) {
      prevAuth = JSON.parse(prevAuth);
    }
    if (prevUser) {
      prevUser = JSON.parse(prevUser);
    }
    if (prevAdmin) {
      prevAdmin = JSON.parse(prevAdmin);
    }
    if (prevAccessToken) {
      prevAccessToken = JSON.parse(prevAccessToken);
    }
    if (prevPropertyId) {
      prevPropertyId = JSON.parse(prevPropertyId);
    }
  } catch (e) {
    console.log("Can't read stored properties.");
  }

  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [user, setUser] = useState(prevUser);
  const [admin, setAdmin] = useState(prevAdmin);
  const [accessToken, setAccessToken] = useState(prevAccessToken);
  const [propertyId, setPropertyId] = useState(prevPropertyId);

  let prevClient = null;

  if (authenticated) {
    const { baseUrl, ...otherConfig } = config;
    prevClient = new OktaAuth({ ...otherConfig, url: baseUrl });

    // prevClient.token
    //   .getWithoutPrompt({
    //     responseType: ["id_token", "access_token"]
    //   })
    //   .then(tokens => {
    //     console.log(`tokens: ${JSON.stringify(tokens, null, 2)}`);
    //   });
  }

  const [client, setClient] = useState(prevClient);

  useEffect(() => {
    window.localStorage.setItem("authenticated", JSON.stringify(authenticated));
    window.localStorage.setItem("user", JSON.stringify(user));
    window.localStorage.setItem("admin", JSON.stringify(admin));
    window.localStorage.setItem("accessToken", JSON.stringify(accessToken));
    window.localStorage.setItem("propertyId", JSON.stringify(propertyId));
  }, [authenticated, user, admin, accessToken, propertyId]);

  /**
   * Cleans up the Okta side of logging out: clears session, client tokens and local
   * properties.
   *
   * DOES NOT revoke the JWT Token on the application side.
   */
  const logout = () => {
    // "Log-out" user from the server-side, ignore any errors due to race conditions.
    // This would normally use the secure-api-hook-dispath hook, to make a secure
    // API call, but it would create a circular dependency (secure-api-hook)

    if (client.session.exists()) {
      try {
        client.session.close();
      } catch (e) {
        console.log(e);
      }
    }
    try {
      client.tokenManager.clear();
      client.tokenManager.off();
    } catch (e) {
      console.log(e);
    }
    setAuthenticated(false);
    setAdmin(false);
    setAccessToken(null);
    setPropertyId(null);
    setUser(null);
    history.push("/");
  };

  const revokeToken = async () => {
    const siteUrl = `${window.CONFIG_API_URL}`;

    let axiosConfig;
    const absoluteUrlRegEx = /^https?:\/\//i;

    if (
      !siteUrl ||
      siteUrl === "undefined" ||
      siteUrl.length === 0 ||
      !absoluteUrlRegEx.test(siteUrl)
    ) {
      axiosConfig = {};
    } else {
      axiosConfig = { baseURL: siteUrl };
    }

    const ax = axios.create(axiosConfig);
    try {
      if (!client) {
        return;
      }
      const token = await client.tokenManager.get("access_token");

      if (!token) {
        return;
      }

      const options = {
        url: `${siteUrl}/${propertyId}/revoke`,
        method: "post",
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      };

      const res = await ax.request(options);
    } catch (e) {
      console.error(e);
    }
  };

  const revokeAndLogout = () => {
    revokeToken();
    logout();
  };

  // Returns a list of property ids give a user.
  const getPropertyIds = () => {
    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/g;
    const groups = user.groups || [];
    return groups.filter((g) => g.match(re));
  };

  // Returns whether or not a user has multiple properties.
  const hasMultipleProperties = () => {
    return getPropertyIds().length > 1;
  };

  const defaultContext = {
    authenticated,
    setAuthenticated,
    accessToken,
    setAccessToken,
    propertyId,
    setPropertyId,
    user,
    setUser,
    admin,
    setAdmin,
    client,
    setClient,
    logout,
    revokeAndLogout,
    getPropertyIds,
    hasMultipleProperties,
  };

  return (
    <AuthContext.Provider value={defaultContext}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
