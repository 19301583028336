import React, {useContext, useEffect, useReducer} from "react";
import {initialState, reducer, useSecureApi,} from "../hooks/secure-api-hook-dispatch";

import {AuthContext} from "./auth-context";

const CmapContext = React.createContext(null);

export const types = {
  setChannelMaps: "set_channel_maps",
  selectChannelMap: "select_channel_map",
  deselectChannelMap: "deselect_channel_map",
};

const channelMapsReducer = (state, action) => {
  switch (action.type) {
    case types.setChannelMaps:
      return { ...state, channelMaps: [...action.payload] };
    case types.selectChannelMap:
      return {
        ...state,
        selectedChannelMap: action.payload,
      };
    case types.deselectChannelMap:
      return {
        ...state,
        selectedChannelMap: undefined,
      };
    default:
      return state;
  }
};

const CMapProvider = ({ children }) => {
  const { authenticated } = useContext(AuthContext);

  const [fetchChannelMapsState, fetchDispatch] = useReducer(
    reducer,
    initialState
  );
  const [fetchChannelMaps] = useSecureApi(
    "channelmaps",
    "get",
    true,
    fetchDispatch
  );

  const [channelMapsState, channelMapsDispatch] = useReducer(
    channelMapsReducer,
    { channelMaps: [] }
  );
  const setChannelMaps = (channelMaps) =>
    channelMapsDispatch({ type: types.setChannelMaps, payload: channelMaps });

  const selectChannelMap = (channelMap) =>
    channelMapsDispatch({ type: types.selectChannelMap, payload: channelMap });

  const deselectChannelMap = () => {
    channelMapsDispatch({ type: types.deselectChannelMap, payload: [] });
  };

  useEffect(() => {
    if (!authenticated) {
      channelMapsDispatch({ type: types.setChannelMaps, payload: [] });
      deselectChannelMap();
    }
  }, [authenticated]);

  useEffect(() => {
    fetchChannelMaps();
  }, [fetchChannelMaps]);

  useEffect(() => {
    if (fetchChannelMapsState.data) {
      let channelMaps = fetchChannelMapsState.data.channelMaps;
      setChannelMaps(channelMaps);
      if (channelMaps && channelMaps.length > 0) {
        selectChannelMap(channelMaps[0]);
      }
    }
  }, [fetchChannelMapsState]);

  const defaultContext = {
    fetchChannelMapsState,
    fetchChannelMaps,
    selectChannelMap,
    deselectChannelMap,
    channelMapsState,
  };

  return (
    <CmapContext.Provider value={defaultContext}>
      {children}
    </CmapContext.Provider>
  );
};
export { CmapContext, CMapProvider };
