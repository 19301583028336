/* eslint-disable react/jsx-one-expression-per-line */
import React, {useEffect, useState} from "react";
import {Button, Card, Grid, Icon, Popup} from "semantic-ui-react";
import {format, parseISO} from "date-fns";
import {useUsers} from "../../hooks/user-hook";

const UserCard = ({ user, self }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openSuspend, setOpenSuspend] = useState(false);
  const { status, created, passwordChanged } = user;
  const { firstName, lastName, login } = user.profile;

  const { deleteState, deleteUser, removeUser } = useUsers();

  const statusColor = (stat) => {
    switch (stat) {
      case "ACTIVE":
        return "green";
      case "RECOVERY":
        return "orange";
      case "DISABLED":
        return "gray";
      default:
        return "black";
    }
  };

  useEffect(() => {
    if (deleteState.successful) {
      removeUser(deleteState.data.id);
    }
  }, [deleteState]);

  // TODO: Implement suspend.
  const handleSuspendUser = () => {
    // suspendUser(propertyId, email, token);
  };

  const handleDeleteUser = () => {
    deleteUser({ resource: user.id });
  };

  const renderDate = (label, dateString) => {
    try {
      if (dateString) {
        const parsedDate = parseISO(dateString);
        const formattedDate = format(parsedDate, "MM/dd/yyyy hh:mm a zzz");
        return `${label} ${formattedDate}`;
      }
    } catch (e) {
      console.log(e);
    }
    return `${label} Unknown`;
  };

  const renderDeleteButton = () => {
    return (
      <Button
        negative
        circular
        icon="delete"
        floated="right"
        size="mini"
        disabled={self}
        loading={deleteState.loading}
      />
    );
  };

  const renderSuspendUserButton = () => {
    return (
      <Button
        circular
        icon={{ name: "pause" }}
        floated="right"
        size="mini"
        disabled
      />
    );
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <Icon floated="left" name="user circle" color={statusColor(status)} />
          {`${firstName} ${lastName}`}
          <Popup
            trigger={renderDeleteButton()}
            on="click"
            open={openDelete}
            onClose={() => setOpenDelete(false)}
            onOpen={() => setOpenDelete(true)}
            position="bottom right"
          >
            {" "}
            <Popup.Header>
              <span>Delete User</span>
            </Popup.Header>
            <Popup.Content>
              <p>Are you sure? This will delete the user permanently.</p>
              <Button.Group>
                <Button
                  content="Confirm"
                  icon="check"
                  onClick={() => {
                    handleDeleteUser();
                    setOpenDelete(false);
                  }}
                  positive
                />
                <Button.Or />
                <Button
                  content="Cancel"
                  icon="cancel"
                  onClick={() => {
                    setOpenDelete(false);
                  }}
                />
              </Button.Group>
            </Popup.Content>
          </Popup>
          <Popup
            trigger={renderSuspendUserButton()}
            on="click"
            open={openSuspend}
            onClose={() => setOpenSuspend(false)}
            onOpen={() => setOpenSuspend(true)}
            position="bottom right"
          >
            {" "}
            <Popup.Header>
              <span>Disable User</span>
            </Popup.Header>
            <Popup.Content>
              <p>This will temporarily Disable the User.</p>
              <Button.Group>
                <Button
                  content="Confirm"
                  icon="check"
                  onClick={() => {
                    handleSuspendUser();
                    setOpenSuspend(false);
                  }}
                  positive
                />
                <Button.Or />
                <Button
                  content="Cancel"
                  icon="cancel"
                  onClick={() => {
                    setOpenSuspend(false);
                  }}
                />
              </Button.Group>
            </Popup.Content>
          </Popup>
        </Card.Header>
        <Card.Meta>{login}</Card.Meta>
      </Card.Content>
      <Card.Content
        extra
        style={{
          justifyContent: "space-between",
        }}
      >
        <Grid>
          <Grid.Column width={8}>
            <Icon floated="left" name="user" />
            {renderDate("Account Created: ", created)}
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Icon floated="left" name="lock" />
            {renderDate("Password Last Changed: ", passwordChanged)}
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
    // </Transition>
  );
};

export default UserCard;
