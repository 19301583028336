import React from "react";
import { AuthContext } from "../contexts/auth-context";

import "./SessionModal.css";

const MODAL_TIMER = 15 * 60 * 1000;
const LOGOUT_TIMER = 60 * 1000;

// JavaScript function scope is a thing
// This function returns two callbacks that share a "timeout" variable for cleanup purposes
const setTimeout = (onTimeout, timer) => {
  let timeout = 0;
  const refreshTimeout = () => {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(onTimeout, timer);
  };
  const unmountTimeout = () => {
    window.clearTimeout(timeout);
  };
  timeout = window.setTimeout(onTimeout, timer);
  return [refreshTimeout, unmountTimeout];
};

const SessionModal = () => {
  const { logout } = React.useContext(AuthContext);
  const [lastFocusedEl, setFocusedEl] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const openPopup = () => {
      setOpen(true);
    };
    if (open) {
      const [, unmount] = setTimeout(logout, LOGOUT_TIMER);
      const lastFocus = document.activeElement;
      const div = document.getElementById("session-modal");
      div.focus();
      setFocusedEl(lastFocus);
      return unmount;
    } else {
      const [refresh, unmount] = setTimeout(openPopup, MODAL_TIMER - LOGOUT_TIMER);
      const mainEl = document.querySelector("#main");
      mainEl.addEventListener("click", refresh);
      mainEl.addEventListener("keyDown", refresh);
      return () => {
        unmount();
        mainEl.removeEventListener("click", refresh);
        mainEl.removeEventListener("keyDown", refresh);
      };
    }
  }, [open]);
  if (!open) return null;
  const doTokenRefresh = (e) => {
    lastFocusedEl.focus();
    setOpen(false);
    e.preventDefault();
  };
  return (
    <div
      id="session-modal"
      tabIndex={0}
      onClick={doTokenRefresh}
      onKeyDown={doTokenRefresh}
    >
      <div className="session-modal-wrap">
        <div className="session-modal-header close"></div>
        <div role="alert" className="session-modal-content">
          <h2>
            Your session is about to end.
          </h2>
          <p>
            Click or press any key to stay signed in.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SessionModal;
