import React, {useCallback, useEffect, useRef, useState,} from "react";
import {Formik} from "formik";
import {DatePicker, Form} from "react-formik-ui";
import {addWeeks, endOfDay, isSameDay, startOfToday} from "date-fns";
import * as yup from "yup";
import {Button, Input, Popup} from "semantic-ui-react";
// import { RoomContext } from "contexts/room-context";
import {useRoom} from "../../hooks/room-hook";
import useSites from "../../hooks/use-sites";

import "./datepicker.css";
import {utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";

const RoomResetCalendar = ({
  initialDateTime,
  setScheduleState,
  setWasScheduling,
  disabled = false,
}) => {
  const calculateMinTime = (date) =>
    isSameDay(date, new Date()) ? new Date() : startOfToday();
  const [minTime, setMinTime] = useState(calculateMinTime(new Date()));

  const datePickerRef = useRef();
  const [openSchedule, setOpenSchedule] = useState(false);
  const [tempResetTime, setTempResetTime] = useState("");

  // const { resetTime, setResetTime } = useContext(RoomContext);

  const {
    scheduleState,
    scheduleRoom,
    unscheduleRoom,
    unscheduleState,
    clearRoomReset,
    updateRoom,
  } = useRoom();

  const { siteState } = useSites();

  const timeZone = siteState.activeSite.timeZone;

  const callbackRef = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.dispatchEvent(
        new MouseEvent("mousedown", {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      );
    }
  }, []);
  useEffect(() => {
    setScheduleState(scheduleState);
  }, [scheduleState]);

  useEffect(() => {}, [scheduleState, scheduleRoom]);

  const onSubmit = (data) => {
    if (disabled) {
      return;
    }
    // date-fns upgrade to 2.0.x (req'd for date-fns-tz) is
    // triggering a different formatted string to be returned,
    // which works on the backend, but causes render issues
    // in the RoomTable.

    if (data.resetTime instanceof Date) {
      const utcDate = zonedTimeToUtc(data.resetTime, timeZone);
      setTempResetTime(utcDate.toISOString());
    } else {
      setTempResetTime(data.resetTime);
    }
    setOpenSchedule(true);
  };

  let initialZonedTime = "";

  if (initialDateTime) {
    initialZonedTime = utcToZonedTime(initialDateTime, timeZone);
  }
  const getSchema = () => {
    return yup.object().shape({
      resetTime: yup.date().min(new Date()),
    });
  };

  const handleScheduleRoomReset = () => {
    if (disabled) {
      return;
    }
    if (tempResetTime && tempResetTime !== "") {
      // setResetTime(tempResetTime);
      scheduleRoom({ data: { resetTime: tempResetTime } });
      updateRoom(tempResetTime);
    } else {
      // setResetTime(null);
      clearRoomReset();
      scheduleRoom({ data: { resetTime: null } });

      // updateRoom(null);
    }
    setWasScheduling(true);
  };

  const handleDateChange = (date) => {
    setMinTime(calculateMinTime(date));
    setOpenSchedule(false);
    console.log(`openSchedule is ${openSchedule}`);
  };

  const renderInput = () => {
    const params = {
      color: "blue",
      labelPosition: "right",
      icon: "clock",
      content: "Schedule Reset Time",
      type: "submit",
      onClick: (event, target) => {},
    };

    return (
      <Input
        action={params}
        icon={{ color: "blue", name: "clock" }}
        iconPosition="left"
        loading={scheduleState.loading || unscheduleState.loading}
        disabled={disabled}
        onMouseOver={closeSchedulerHandler}
      />
    );
  };

  const closeSchedulerHandler = (event) => {
    setOpenSchedule(false);
  };

  const renderDatePicker = () => {
    return (
      <DatePicker
        onSelect={handleDateChange}
        name="resetTime"
        placeholderText="Click to Select Date and Time"
        showTimeSelect
        minDate={new Date()}
        maxDate={addWeeks(new Date(), 2)}
        minTime={minTime}
        maxTime={endOfDay(new Date())}
        timeFormat="h:mm aa"
        dateFormat="MMMM d, yyyy h:mm aa"
        timeIntervals={15}
        isClearable
        shouldCloseOnSelect
        customInput={renderInput()}
        disabled={disabled}
        id="datePicker"
      />
    );
  };

  return (
    <Formik
      initialValues={{
        resetTime: initialZonedTime,
      }}
      enableReinitialize
      validationSchema={getSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Popup
            trigger={renderDatePicker()}
            on="click"
            open={openSchedule}
            eventsEnabled
            onClose={() => setOpenSchedule(false)}
            onOpen={() => setOpenSchedule(true)}
            position="bottom right"
            closeOnTriggerMouseLeave
            closeOnTriggerBlur
          >
            <Popup.Header>
              <span>Schedule a Room Reset?</span>
            </Popup.Header>
            <Popup.Content>
              {/* <input type="Text" value="Focus Test" autoFocus onFocus={e => e.currentTarget.select()} /> */}
              <p ref={callbackRef}>
                Hit Confirm to save changes. This will Schedule a Room Reset for
                the selected time. If the date is blank, any scheduled reset
                will be removed.
              </p>
              <Button.Group>
                <Button
                  content="Confirm"
                  icon="check"
                  onClick={() => {
                    setOpenSchedule(false);
                    handleScheduleRoomReset();
                  }}
                  positive
                />
                <Button.Or />
                <Button
                  content="Cancel"
                  icon="cancel"
                  onClick={() => {
                    setOpenSchedule(false);
                  }}
                />
              </Button.Group>
            </Popup.Content>
          </Popup>
        </Form>
      )}
    </Formik>
  );
};

export default RoomResetCalendar;
