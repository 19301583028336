// Okta configuration object.
// Shared between Okta SecureRoute and SignInWidget (reason for redundant fields);
const redirectUri = `${window.location.origin}/implicit/callback`;

const config = {
  baseUrl: `${window.CONFIG_OKTA_BASE_URL}`,
  getAccessToken: true,
  getIdToken: true,
  clientId: `${window.CONFIG_OKTA_CLIENT_ID}`,
  logo: "cox_business_logo.png",
  redirectUri,
  i18n: {
    en: {
      "primaryauth.title": "Contour for Hospitality Portal",
      "primaryauth.submit": "Sign In"
    }
  },
  //  logo: "/react.svg",
  authParams: {
    responseType: ["id_token", "token"],
    issuer: `${window.CONFIG_OIDC_ISSUER}`,
    display: "popup",
    // "popup" interacts with response type and is critical.
    // display: "page",
    scopes: ["openid", "email", "profile", "frontdesk"]
  }, // TODO: Company's Logo: logo: 'url'
  features: {
    router: true,
    selfServiceUnlock: true
  }
};

export default config;
