import React, {useContext, useEffect} from "react";
import {AuthContext} from "../contexts/auth-context";

import {Container, Grid, Header, Icon, Image, Label, Loader, Popup,} from "semantic-ui-react";
import {Link, Redirect, Route} from "react-router-dom";
import RoomPage from "./rooms/RoomPage";
import SignOutButton from "./SignOutButton";
import SiteDropdown from "./sites/SiteDropdown";

import "./App.css";
import Menu from "./Menu";
import LoginPage from "./LoginPage";
import UserPage from "./users/UserPage";
import HelpPage from "./HelpPage";
import ChannelMapPage from "./channelmaps/ChannelMapPage";

import {displayTime, makeLogoUrl} from "../apis/utils";

import useSites from "../hooks/use-sites";
import SessionModal from "./SessionModal";

export default () => {
  const {
    authenticated,
    user,
    admin,
    hasMultipleProperties,
    propertyId,
  } = useContext(AuthContext);

  const {
    siteState,
    defaultLogo,
    selectSiteByPropertyId,
    fetchSitesState,
  } = useSites();

  useEffect(() => {
    if (!siteState.activeSite && fetchSitesState.data) {
      selectSiteByPropertyId(propertyId);
    }
  }, [propertyId, fetchSitesState, siteState.activeSite]);

  const { logoBlob, timeZone, resetTime, icomsMasterAccount } =
    siteState.activeSite || {};

  const renderUser = () => {
    if (user) {
      if (user.groups) {
        console.log("user groups:");
        user.groups.forEach((g) => console.log(g));
      }

      return (
        <Header
          content={user.name}
          subheader={`${admin ? "(Admin)\n" : ""} ${user.email} `}
          floated="right"
          icon={{ name: "user circle", color: "blue" }}
          as={Link}
          to="/users"
        />
      );
    }
    return (
      <Header>
        <Loader active content="Loading User..." />
      </Header>
    );
  };

  const renderAccount = () => {
    if (admin) {
      return (
        <Label horizontal color="blue">
          <Icon name="info circle" />
          {icomsMasterAccount}
        </Label>
      );
    }
    return "";
  };

  const renderSites = () => {
    if (user && hasMultipleProperties()) {
      return <SiteDropdown />;
    }

    return "";
  };

  if (!authenticated) {
    return <LoginPage />;
  }
  return (
    <>
      <SessionModal />
      <Container className="segment" id="main">
        <Grid>
          <Grid.Row className="top-bar">
            <Popup
              content="Please call customer service to update this setting."
              trigger={
                <div>
                  {`Daily Reset Time: ${
                    resetTime ? displayTime(resetTime, timeZone) : "loading"
                  }`}
                </div>
              }
            />
          </Grid.Row>
          <Grid.Column width={3}>
            <Image src={`data:image/jpeg;base64,${logoBlob}`} />
          </Grid.Column>
          <Grid.Column width={2}>{renderAccount()}</Grid.Column>
          <Grid.Column width={5}>{renderSites()}</Grid.Column>
          <Grid.Column width={4}>{renderUser()}</Grid.Column>
          <Grid.Column width={2}>
            <SignOutButton />
          </Grid.Column>
        </Grid>
        <Menu />
        <Route path="/rooms" component={RoomPage} />
        <Route path="/channelmaps" component={ChannelMapPage} />
        <Route path="/users" component={UserPage} />
        <Route path="/help" component={HelpPage} />
        <Redirect exact from="/" to="/rooms" />
        <div className="cox-footer-bar" />
        <footer className="cox-bottom-footer">
          © 1998 - 2021 Cox Communications, Inc.
        </footer>
      </Container>
    </>
  );
};
