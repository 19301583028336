import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Grid,
  Icon,
  Image,
  List,
  Loader,
  Message,
  Popup,
  Segment,
} from "semantic-ui-react";
import RoomResetCalendar from "./RoomResetCalendar";
import {useRoom} from "../../hooks/room-hook";
import roomImg from "../../assets/picture_frame.png";
import {capitalizeWords} from "../../apis/utils";
import {format, utcToZonedTime} from "date-fns-tz";
import useSites from "../../hooks/use-sites";

const RoomCard = (props) => {
  // const { selectedRoom, setSelectedRoom } = useContext(RoomContext);

  const [openReboot, setOpenReboot] = useState(false);
  const [openDeviceReboot, setOpenDeviceReboot] = useState(null);
  const [openReset, setOpenReset] = useState(false);
  // Obsolete
  const [wasResetting, setWasResetting] = useState(false);
  const [wasRebooting, setWasRebooting] = useState(false);
  const [wasDeviceRebooting, setWasDeviceRebooting] = useState(null);
  const [wasScheduling, setWasScheduling] = useState(false);
  const [scheduleState, setScheduleState] = useState(false);

  const {
    clearRoom,
    deselectRoom,
    resetState,
    resetRoom,
    rebootState,
    rebootRoom,
    rebootDeviceState,
    rebootDevice,
    roomsState,
  } = useRoom();

  const { siteState } = useSites();

  let timeZone = "America/New_York";

  if (siteState.activeSite) {
    timeZone = siteState.activeSite.timeZone;
  }

  const isAdmin = true;

  useEffect(() => {
    console.log(`${JSON.stringify(scheduleState, null, 2)}`);
    if (scheduleState.loading) {
      setWasScheduling(true);
    }
  }, [scheduleState]);

  useEffect(() => {
    setWasRebooting(false);
    setWasDeviceRebooting(null);
    setWasResetting(false);
    setWasScheduling(false);
  }, [roomsState.selectedRoom]);

  const renderResetButton = () => {
    return (
      <Button
        secondary
        floated="right"
        content="Reset"
        icon="trash"
        loading={resetState.loading}
        disabled={!isAdmin}
      />
    );
  };

  const renderRebootButton = () => {
    return (
      <Button
        color="red"
        floated="right"
        content="Reboot"
        icon="sync"
        loading={rebootState.loading}
        disabled={!isAdmin}
      />
    );
  };

  const renderDeviceRebootButton = (serial) => {
    return (
      <Button
        color="red"
        floated="right"
        content="Reboot Device"
        icon="sync"
        id={serial}
        loading={rebootDeviceState.loading}
        disabled={!isAdmin}
      />
    );
  };

  const renderDevices = () => {
    let safeDevices = devices || [];

    return safeDevices.map((device) => (
      <List.Item key={device.serialNumber}>
        <List.Content>
          <Grid columns={36}>
            <Grid.Row>
              <Grid.Column>
                <Icon name={device.deviceType == "video" ? "tv" : "hdd"} />
              </Grid.Column>
              <Grid.Column width={3}>
                Serial:
                <br />
                {device.serialNumber}
              </Grid.Column>
              <Grid.Column width={3}>
                Type:
                <br />
                {capitalizeWords(device.deviceType)}
              </Grid.Column>
              <Grid.Column width={3}>
                ECM MAC:
                <br />
                {device.ecmMacAddress}
              </Grid.Column>
              <Grid.Column width={3}>
                Host MAC:
                <br />
                {device.hostMacAddress}
              </Grid.Column>
              <Grid.Column width={3} floated="right">
                <Popup
                  trigger={renderDeviceRebootButton(device.serialNumber)}
                  on="click"
                  open={openDeviceReboot == device.serialNumber}
                  onClose={() => setOpenDeviceReboot(null)}
                  onOpen={() => setOpenDeviceReboot(device.serialNumber)}
                  position="bottom right"
                >
                  <Popup.Header>
                    <span>Reboot {device.serialNumber}?</span>
                  </Popup.Header>
                  <Popup.Content>
                    <p>
                      Are you sure? This will interrupt any current viewing, and
                      may take several minutes to complete.
                    </p>
                    <Button.Group>
                      <Button
                        content="Confirm"
                        icon="check"
                        onClick={(e) => {
                          handleDeviceReboot(e);
                          setOpenDeviceReboot(null);
                        }}
                        id={device.serialNumber}
                        positive
                      />
                      <Button.Or />
                      <Button
                        content="Cancel"
                        icon="cancel"
                        onClick={() => {
                          setOpenDeviceReboot(null);
                        }}
                      />
                    </Button.Group>
                  </Popup.Content>
                </Popup>
                {wasDeviceRebooting == device.serialNumber &&
                  rebootDeviceState.error && (
                    <Message
                      content={`Error rebooting device ${device.serialNumber} in room ${room} .\n${rebootDeviceState.error}`}
                      error
                      onDismiss={() => setWasDeviceRebooting(null)}
                      size="tiny"
                      floating
                    />
                  )}
                {wasDeviceRebooting == device.serialNumber &&
                  rebootDeviceState.successful && (
                    <Message
                      content={`Rebooting device ${device.serialNumber} in room ${room}.`}
                      successful
                      onDismiss={() => setWasDeviceRebooting(null)}
                      size="tiny"
                      floating
                    />
                  )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </List.Content>
      </List.Item>
    ));
  };

  const handleRebootRoom = () => {
    rebootRoom();
    setWasRebooting(true);
  };

  const handleDeviceReboot = (e) => {
    rebootDevice({ resource: e.target.id });
    setWasDeviceRebooting(e.target.id);
  };

  const handleResetRoom = () => {
    resetRoom();
    setWasResetting(true);
  };

  const { room, roomType, vcmHandle, channelMapName, resetTime, devices } =
    roomsState.selectedRoom || {};

  let resetTimeMessage;

  if (resetTime) {
    const zonedDate = utcToZonedTime(resetTime, timeZone);
    const formattedDate = format(zonedDate, "MM/dd/yyyy hh:mm a zzz", {
      timeZone,
    });
    resetTimeMessage = `set to ${formattedDate}`;
  } else {
    resetTimeMessage = "unscheduled";
  }

  if (devices) {
    console.log(`devices: ${JSON.stringify(devices, 2)}`);
  }

  if (!room) {
    return (
      <Message
        info
        icon="point hand down"
        header="Select A Room"
        content="Select a Room below to reset the device settings, schedule a reset or perform a reboot."
        style={{ height: "174px" }}
      />
    );
  }

  if (props.loading) {
    return (
      <Dimmer active style={{ height: "174px" }}>
        <Loader active content="Loading..." />
      </Dimmer>
    );
  }
  return (
    <Card.Group>
      <Card fluid>
        <Segment content="Room Details" icon="hotel" inverted color="blue" />
        <Card.Content>
          <Button
            style={{ marginLeft: "auto" }}
            className="float right"
            floated="right"
            icon="close"
            size="mini"
            onClick={() => {
              setWasRebooting(false);
              setWasDeviceRebooting(null);
              setWasResetting(false);
              deselectRoom();
            }}
          />
          <Image floated="left" size="mini" src={roomImg} />
          <Card.Header size="large">Room: {room}</Card.Header>
          <Card.Description>Type: {capitalizeWords(roomType)}</Card.Description>
          <Card.Meta>
            {/* {`Channel Lineup: ${channelMapName} (${vcmHandle})`} */}
          </Card.Meta>
          <Card.Content extra>
            <Divider />
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <RoomResetCalendar
                    initialDateTime={resetTime}
                    setScheduleState={setScheduleState}
                    setWasScheduling={setWasScheduling}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button.Group floated="right">
                    <Popup
                      trigger={renderResetButton()}
                      on="click"
                      open={openReset}
                      onClose={() => setOpenReset(false)}
                      onOpen={() => setOpenReset(true)}
                      position="bottom right"
                    >
                      {" "}
                      <Popup.Header>
                        <span>Reset the devices in this room?</span>
                      </Popup.Header>
                      <Popup.Content>
                        <p>
                          Are you sure? This will delete any configurations
                          guests have made.
                        </p>
                        <Button.Group>
                          <Button
                            content="Confirm"
                            icon="check"
                            onClick={() => {
                              handleResetRoom();
                              setOpenReset(false);
                            }}
                            positive
                          />
                          <Button.Or />
                          <Button
                            content="Cancel"
                            icon="cancel"
                            onClick={() => {
                              setOpenReset(false);
                            }}
                          />
                        </Button.Group>
                      </Popup.Content>
                    </Popup>
                    <Popup
                      trigger={renderRebootButton()}
                      on="click"
                      open={openReboot}
                      onClose={() => setOpenReboot(false)}
                      onOpen={() => setOpenReboot(true)}
                      position="bottom right"
                    >
                      <Popup.Header>
                        <span>Reboot all the devices in this room?</span>
                      </Popup.Header>
                      <Popup.Content>
                        <p>
                          Are you sure? This will interrupt any current viewing,
                          and may take several minutes to complete.
                        </p>
                        <Button.Group>
                          <Button
                            content="Confirm"
                            icon="check"
                            onClick={() => {
                              handleRebootRoom();
                              setOpenReboot(false);
                            }}
                            positive
                          />
                          <Button.Or />
                          <Button
                            content="Cancel"
                            icon="cancel"
                            onClick={() => {
                              setOpenReboot(false);
                            }}
                          />
                        </Button.Group>
                      </Popup.Content>
                    </Popup>
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <h3>Devices:</h3>
            <List divided relaxed>
              {renderDevices()}
            </List>
          </Card.Content>
          {wasResetting && resetState.error && (
            <Message
              content={`Reset trigger for room ${room} failed.\n${resetState.error}`}
              error
              onDismiss={() => setWasResetting(false)}
              size="small"
              floating
            />
          )}
          {wasResetting && resetState.successful && (
            <Message
              content={`Reset trigger for room ${room} was successful.`}
              success
              onDismiss={() => setWasResetting(false)}
              size="small"
              floating
            />
          )}
          {wasRebooting && rebootState.error && (
            <Message
              content={`Reboot trigger for room ${room} failed.\n${rebootState.error}`}
              error
              onDismiss={() => setWasResetting(false)}
              size="small"
              floating
            />
          )}
          {wasRebooting && rebootState.successful && (
            <Message
              content={`Reboot trigger for room ${room} was successful.`}
              success
              onDismiss={() => setWasRebooting(false)}
              size="small"
              floating
            />
          )}
          {wasScheduling && scheduleState.error && (
            <Message
              content={`Schedule trigger for room ${room} failed.\n${scheduleState.error}`}
              error
              onDismiss={() => setWasScheduling(false)}
              size="small"
              floating
            />
          )}
          {wasScheduling && scheduleState.successful && (
            <Message
              // content={`Scheduled reset for room ${room} at ${resetTime} was successful.`}
              success
              onDismiss={() => setWasScheduling(false)}
              size="small"
              floating
            >
              Room {room} was successfully {resetTimeMessage}.
            </Message>
          )}
        </Card.Content>
      </Card>
    </Card.Group>
  );
};

export default RoomCard;
