import React from "react";
import {Dropdown, Image} from "semantic-ui-react";
import useSites from "../../hooks/use-sites";

const SiteDropdown = () => {
  const { selectSite, deselectSite, siteState } = useSites();

  const handleSiteChange = (event, { value }) => {
    deselectSite();
    selectSite(value);
  };

  let activeOption = null;

  const siteOptions = siteState.sites.map((site) => {
    let active = siteState.activeSite && siteState.activeSite.id == site.id;

    let option = {
      key: site.id,
      // "image" doesn't work (bug in semantic ui).
      text: (
        <span>
          <Image avatar src={`data:image/jpeg;base64,${site.logoBlob}`} />
          {site.name}
        </span>
      ),
      value: site.id,
      active,
    };

    if (active) {
      activeOption = option;
    }
    return option;
  });

  let activeOptionId = activeOption ? activeOption.value : null;
  return (
    <Dropdown
      placeholder="Select Site"
      onChange={handleSiteChange}
      fluid
      selection
      options={siteOptions}
      value={activeOptionId}
    />
  );
};

export default SiteDropdown;
