import React from "react";
import { AuthProvider } from "../contexts/auth-context";
import { SiteProvider } from "../contexts/site-context";
import { RoomProvider } from "../contexts/room-context";
import { UserProvider } from "../contexts/user-context";
import { CMapProvider } from "../contexts/cmap-context";

export default ({ children }) => {
  return (
    <AuthProvider>
      <SiteProvider>
        <CMapProvider>
          <UserProvider>
            <RoomProvider>{children}</RoomProvider>
          </UserProvider>
        </CMapProvider>
      </SiteProvider>
    </AuthProvider>
  );
};
