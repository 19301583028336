import React, {createContext, useContext, useReducer} from "react";
import {makeLogoUrl} from "../apis/utils";

const SiteStateContext = createContext(null);
const SiteDispatchContext = createContext(null);

export const types = {
  set: "set",
  select: "select",
  deselect: "deselect",
  selectSiteByPropertyId: "selectSiteByPropertyId",
};

export const initialState = {
  sites: [],
  activeSite: null,
};

const reducer = (state, action) => {
  let site;
  let activeSite;
  switch (action.type) {
    case types.set:
      const sitesList = action.payload ? [...action.payload] : [];
      const sites = sitesList.map((s) => {
        return { ...s, logoUrl: makeLogoUrl(s) };
      });
      return { ...state, sites };
    case types.select:
      const id = action.payload;
      activeSite = state.sites.filter((s) => s.id === id)[0];
      return { ...state, activeSite };
    case types.deselect:
      return { ...state, activeSite: null };
    case types.selectSiteByPropertyId:
      const propertyId = action.payload;
      activeSite = state.sites.filter((s) => s.propertyId === propertyId)[0];
      return { ...state, activeSite };
    default:
      return state;
  }
};

const SiteProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SiteStateContext.Provider value={state}>
      <SiteDispatchContext.Provider value={dispatch}>
        {children}
      </SiteDispatchContext.Provider>
    </SiteStateContext.Provider>
  );
};

const useSiteState = () => {
  const context = useContext(SiteStateContext);
  if (context === undefined) {
    throw new Error(
      "SiteStateContext must be used in SiteProvider context - find the top-level component and make sure that it's wrapped in SiteProvider."
    );
  }
  return context;
};

const useSiteDispatch = () => {
  const context = useContext(SiteDispatchContext);
  if (context === undefined) {
    throw new Error(
      "SiteDispatchContext must be used in SiteProvider context - find the top-level component and make sure that it's wrapped in SiteProvider."
    );
  }
  return context;
};

const setSites = (dispatch, sites) => {
  dispatch({ type: types.set, payload: sites });
};
const selectSite = (dispatch, site) => {
  dispatch({ type: types.select, payload: site });
};
const deselectSite = (dispatch) => {
  dispatch({ type: types.deselect });
};
const selectSiteByPropertyId = (dispatch, propertyId) => {
  dispatch({ type: types.selectSiteByPropertyId, payload: propertyId });
};

export {
  SiteProvider,
  useSiteState,
  useSiteDispatch,
  setSites,
  selectSite,
  deselectSite,
  selectSiteByPropertyId,
};
