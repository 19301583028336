import React from "react";
import {Modal} from "semantic-ui-react";
import SignInWidget from "./SignInWidget";

export default () => {
  return (
    <Modal
      defaultOpen
      style={{ height: "456px", width: "398px" }}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      basic
    >
      <Modal.Content style={{ boxSizing: "content-box", padding: "0px" }}>
        <SignInWidget />
      </Modal.Content>
    </Modal>
  );
};
