import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "date-time-format-timezone";
import "fomantic-ui-css/semantic.css";
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";

import App from "./components/App";
import Root from "./components/Root";
import history from "./routing/history";

ReactDOM.render(
  <Root>
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </Root>,
  document.querySelector("#root")
);
