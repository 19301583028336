import React, {createContext, useReducer} from "react";

const UserContext = createContext(null);

const reducer = (state, action) => {
  switch (action.type) {
    case "add":
      return { ...state, users: [action.payload, ...state.users] };
    case "clear":
      return { ...state, users: [] };
    case "remove":
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload)
      };
    default:
      return state;
  }
};

const initialUsers = {
  users: []
};

const UserProvider = ({ children }) => {
  const [usersState, usersDispatch] = useReducer(reducer, initialUsers);

  const addUser = user => {
    usersDispatch({ type: "add", payload: user });
  };

  const clearUsers = () => {
    usersDispatch({ type: "clear" });
  };

  const removeUser = userId => {
    usersDispatch({ type: "remove", payload: userId });
  };

  const defaultContext = {
    usersState,
    addUser,
    clearUsers,
    removeUser
  };
  return (
    <UserContext.Provider value={defaultContext}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
