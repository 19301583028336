import {format, utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";
import propertyLogo from "../assets/default_logo.png";

const logoUrl = `${window.CONFIG_LOGO_URL}`;

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

// Creates a FQDN logo using the local path and server endpoint.
// Returns a default image if the logo isn't set.
export const makeLogoUrl = (site) => {
  return site.logo ? `${logoUrl}/${site.logo}` : propertyLogo;
};

export const displayTime = (timeString, timeZone) => {
  // We only care about the time, so create a dummy date and populate it with the parsed time components.
  const timeParts = timeString.split(":");
  const dummyDate = new Date();
  console.log(`Dummy date: ${dummyDate}`);
  const utcDate = zonedTimeToUtc(dummyDate, timeZone);
  console.log(`UTC date: ${utcDate}`);
  utcDate.setUTCHours(timeParts[0]);
  utcDate.setUTCMinutes(timeParts[1]);
  utcDate.setUTCSeconds(0);
  console.log(`Time parts: ${timeParts[0]} ${timeParts[1]}`);
  console.log(`Updated UTC date: ${utcDate}`);

  const zonedDate = utcToZonedTime(utcDate, timeZone);
  console.log(`zonedDate date: ${zonedDate}`);
  return format(zonedDate, "h:mm a zzz", { timeZone });
};

export const extractErrorMessage = (error) => {
  if (error) {
    // Handle Okta:
    if (error.response) {
      if (error.response.data) {
        if (
          error.response.data.errorCauses &&
          error.response.data.errorCauses.length > 0
        ) {
          return error.response.data.errorCauses[0].errorSummary;
        }
      }
    } else if (error.message) {
      return error.message;
    }
  }
  return null;
};

// left-pad with zeros:
export const leftPad = (value, digits) => {
  return ("000000000000000" + value).slice(-digits);
};

// Uppercase first letter (Capitalize first word):
export const capitalize = (word) => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return "";
};

// Given a string of words, capitalizes all words and returns a string.
export const capitalizeWords = (arr) => {
  if (arr) {
    return arr
      .split(/\s/)
      .map(capitalize)
      .join(" ");
  }
  return "";
};

// Case-insensitive, portable search for react-table.
export const includes = (targetString, searchString) => {
  return targetString.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
};
