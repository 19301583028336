import {useContext, useReducer} from "react";

import {initialState, reducer, useSecureApi,} from "./secure-api-hook-dispatch";

import {UserContext} from "../contexts/user-context";

export const useUsers = () => {
  const ready = true;

  const { usersState, clearUsers, removeUser, addUser } = useContext(
    UserContext
  );

  const [createState, createDispatch] = useReducer(reducer, initialState);
  const [createUser] = useSecureApi(`users`, "post", ready, createDispatch);

  const [fetchState, fetchDispatch] = useReducer(reducer, initialState);
  const [fetchUsers] = useSecureApi("users", "get", ready, fetchDispatch);

  const [deleteState, deleteDispatch] = useReducer(reducer, initialState);
  const [deleteUser] = useSecureApi(
    (userId) => `users/${userId}`,
    "delete",
    ready,
    deleteDispatch
  );

  const [resetPasswordState, resetPasswordDispatch] = useReducer(
    reducer,
    initialState
  );
  const [resetPassword] = useSecureApi(
    (userId) => `users/${userId}/resetPassword`,
    "post",
    ready,
    resetPasswordDispatch
  );

  const [changePasswordState, changePasswordDispatch] = useReducer(
    reducer,
    initialState
  );

  const [changePassword] = useSecureApi(
    (userId) => `users/${userId}/changePassword`,
    "post",
    ready,
    changePasswordDispatch
  );

  return {
    usersState,
    clearUsers,
    removeUser,
    addUser,
    createState,
    createUser,
    fetchState,
    fetchUsers,
    deleteState,
    deleteUser,
    resetPasswordState,
    resetPassword,
    changePasswordState,
    changePassword,
  };
};
