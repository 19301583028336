import React, {useContext, useEffect, useState} from "react";
import {Card, Header, Loader, Message, Segment} from "semantic-ui-react";
import UserForm from "./UserForm";
import {useUsers} from "../../hooks/user-hook";
import useSites from "../../hooks/use-sites";
import {AuthContext} from "../../contexts/auth-context";
import UserCard from "./UserCard";

const UserPage = () => {
  const { siteState } = useSites();
  const [openCreateUser, setOpenCreateUser] = useState(null);

  const {
    createState,
    fetchState,
    fetchUsers,
    usersState,
    addUser,
    clearUsers,
  } = useUsers();
  const { admin, user } = useContext(AuthContext);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (fetchState.data) {
      try {
        console.log("clearing users.");
        clearUsers();
        console.log("adding users");
        fetchState.data.forEach((u) => addUser(u));
      } catch (e) {
        console.log("Error processing users. Check network.");
      }
    }
  }, [fetchState]);

  useEffect(() => {
    clearUsers();
    fetchUsers();
  }, [siteState.activeSite]);

  const renderUsers = () => {
    try {
      const items = admin
        ? usersState.users
        : usersState.users.filter((u) => u.profile.email === user.email);

      const cards = items.map((u) => {
        return (
          <UserCard
            user={u}
            key={u.id}
            self={u.profile.email === user.email}
            admin={admin}
          />
        );
      });

      return <Card.Group>{cards}</Card.Group>;
    } catch (e) {
      return <Message error content="Error loading users." />;
    }
  };

  if (fetchState.error) {
    if (fetchState.error.message) {
      return <Message content={fetchState.error.message} />;
    }
    return <Message content={JSON.stringify(fetchState.error)} />;
  }

  if (fetchState.loading) {
    return <Loader content="Loading Users..." />;
  }

  return (
    <Segment>
      {admin && <UserForm open={openCreateUser} setOpen={setOpenCreateUser} />}
      <Header content={admin ? "Users" : "Account Info"} />
      {user && fetchState.data && renderUsers()}
    </Segment>
  );
};

export default UserPage;
