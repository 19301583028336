import React, {useContext} from "react";
import {Button} from "semantic-ui-react";
import {AuthContext} from "../contexts/auth-context";
// import { RevokeContext } from "contexts/revoke-context";
// import { useLogout } from "hooks/logout-hook";

const SignOutButton = () => {
  const { revokeAndLogout } = useContext(AuthContext);
  // const { logout } = useContext(AuthContext);
  // const { revokeAndLogout } = useLogout();
  // const { revokeToken } = useContext(RevokeContext);
  return (
    <Button.Group floated="right" size="tiny">
      <Button
        // onClick={logout}
        onClick={revokeAndLogout}
        // onClick={revokeToken}
        content="Sign Out"
        icon="sign out"
        primary
        labelPosition="left"
        floated="right"
        size="small"
      />
    </Button.Group>
  );
};

export default SignOutButton;
