import React, {useState} from "react";
import {Form, Label, Popup} from "semantic-ui-react";

const FormField = ({
  name,
  errors,
  touched,
  placeholder,
  label,
  handleChange,
  handleBlur,
  value,
  type = "text",
  position = "top left",
  offset
}) => {
  const [open, setOpen] = useState(!!errors && touched);

  return (
    <Form.Field required>
      <Label
        as="label"
        content={label}
        style={{ backgroundColor: "transparent", borderColor: "transparent" }}
      />
      <Popup
        open={!!errors && touched}
        // offset="187px, 0"
        // onOpen={handleOpen}
        position={position}
        content={
          <Label
            content={errors}
            basic
            color="red"
            style={{ borderColor: "transparent" }}
          />
        }
        trigger={
          <Form.Input
            autoComplete={false}
            onChange={handleChange}
            onBlur={handleBlur}
            name={name}
            placeholder={placeholder}
            type={type}
            error={errors && touched}
            value={value}
          />
        }
      />
    </Form.Field>
  );
};
export default FormField;
