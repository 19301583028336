import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../contexts/auth-context";
import OktaSignIn from "@okta/okta-signin-widget";
import config from "../okta.config";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import history from "../routing/history";
import "./SignInWidget.css";

const SignInWidget = () => {
  const {
    setAccessToken,
    setAdmin,
    setAuthenticated,
    setClient,
    setPropertyId,
    setUser,
  } = useContext(AuthContext);
  const [widget, setWidget] = useState();
  const node = useRef();

  const handleError = (err) => {
    console.log("error logging in.", err);
  };

  const handleSuccess = async (res) => {
    setClient(widget.authClient);

    var tokens = res.tokens;

    widget.authClient.tokenManager.add("id_token", tokens.idToken);
    widget.authClient.tokenManager.add("access_token", tokens.accessToken);

    widget.authClient.tokenManager.on("expired", function(key, expiredToken) {
      console.log("Token with key", key, " has expired:");
      console.log(expiredToken);
    });

    widget.authClient.tokenManager.on("error", (err) => {
      console.log(`TokenManager error: ${err.message}`);
    });

    const id = await widget.authClient.tokenManager.get("id_token");
    const access = await widget.authClient.tokenManager.get("access_token");

    const parsedId = widget.authClient.token.decode(id.idToken);

    setUser(parsedId);
    setAccessToken(access.accessToken);

    const parsedAccess = widget.authClient.token.decode(access.accessToken);

    const { groups } = parsedAccess.payload;

    const admin = groups.includes(`${window.CONFIG_OKTA_ADMIN_GROUP_NAME}`);

    setAdmin(admin);

    setPropertyId(parsedAccess.payload.hwfd_loc_guid);

    // TODO: subscribe to changes using "on"/"off" to update the context.

    widget.authClient.token
      .getUserInfo(access)
      .then((user) => {
        setUser({
          email: user.email,
          name: user.name,
          firstName: user.given_name,
          lastName: user.sur_name,
          id: user.sub,
          groups,
        });
      })
      .catch((e) => console.log(e));

    setAuthenticated(true);
    history.push("/rooms");
  };
  useEffect(() => {
    try {
      if (widget) {
        widget.show();
      } else {
        setWidget(new OktaSignIn(config));
      }
    } catch (e) {
      console.error(e);
    }
    return () => {
      if (widget) {
        widget.remove();
        setWidget(null);
      }
    };
  }, [widget]);

  useEffect(() => {
    if (widget) {
      if (!widget.authClient.isLoginRedirect()) {
        widget.renderEl({ el: node.current }, handleSuccess, handleError);
      } else {
        widget.authClient.token
          .parseFromUrl()
          .then(function(res) {
            var tokens = res.tokens;

            widget.tokenManager.add("id_token", accessToken.idToken);
            widget.tokenManager.add("access_token", tokens.accessToken);
          })
          .catch(function(err) {
            console.log("error logging in.", err);
          });
      }
    }
  }, [widget]);

  return <div ref={node} style={{ margin: "0px" }} />;
};

export default SignInWidget;
