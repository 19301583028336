import React, {useEffect, useState} from "react";
import {Button, Card, Dimmer, Dropdown, Grid, Header, Image, Loader, Modal, Table,} from "semantic-ui-react";
import useSites from "../../hooks/use-sites";
import {useChannelMaps} from "../../hooks/use-channelmaps";
// import defaultChannelLogo from "assets/picture_frame.png";
import coxLogo from "../../assets/cox_business_logo.png";

const ChannelMapCard = (props) => {
  const { siteState, defaultLogo } = useSites();
  const {
    fetchChannelMaps,
    fetchChannelMapsState,
    channelMapsState,
    selectChannelMap,
  } = useChannelMaps();

  const [open, setOpen] = useState(false);

  const { data, success, error, loading } = fetchChannelMapsState;
  const { logo } = siteState.activeSite || {};

  useEffect(() => {
    if (fetchChannelMapsState.data) {
      // setChannelMaps(fetchChannelMapsState.data.channelMaps);
      console.log(`${fetchChannelMapsState.data}`);
    }
  }, [fetchChannelMapsState]);

  useEffect(() => {
    selectChannelMap(undefined);
    fetchChannelMaps();
  }, [siteState.activeSite]);

  const handleChannelMapChange = (event, { id }) => {
    if (data && data.channelMaps) {
      const single = data.channelMaps.filter((a) => a.vcmHandle === id);
      if (single && single.length > 0) {
        selectChannelMap(single[0]);
      }
    }
  };

  const renderChannels = () => {
    const { selectedChannelMap } = channelMapsState;

    //TODO: print table breaks.
    const safeChannels =
      (selectedChannelMap && selectedChannelMap.channels) || [];

    const half = safeChannels.length / 2;

    const firstHalf = safeChannels.slice(0, half);
    const secondHalf = safeChannels.slice(half, safeChannels.length);

    return firstHalf.map((ch, idx, arr) => {
      let ch2 = secondHalf[idx];
      let cname = idx > 0 && idx % 40 == 0 ? "print-break" : "";
      return (
        <Table.Row className={cname}>
          <Table.Cell>{ch.channelNumber}</Table.Cell>
          <Table.Cell>
            {/* <Image src={ch.logo.small.secureURL} mini /> */}
            {ch.name}
          </Table.Cell>
          <Table.Cell>{ch2.channelNumber}</Table.Cell>
          <Table.Cell>
            {/* <Image src={ch.logo.small.secureURL} mini /> */}
            {ch2.name}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const { channels } = channelMapsState.selectedChannelMap || {};

  // if (!selectedChannelMap) {
  //   return (
  //     <Message
  //       info
  //       icon="point hand down"
  //       header="Select A Channel Lineup"
  //       content="Select a Channel Lineup to View the List of Channels."
  //       style={{ height: "174px" }}
  //     />
  //   );
  // }

  if (loading) {
    return (
      <Dimmer active inverted style={{ height: "174px" }}>
        <Loader active inverted content="Loading..." />
      </Dimmer>
    );
  }

  let activeOption = null;

  const channelMapOptions = channelMapsState.channelMaps.map((channelMap) => {
    let active =
      channelMapsState.selectedChannelMap &&
      channelMapsState.selectedChannelMap.vcmHandle == channelMap.vcmHandle;

    let option = {
      key: channelMap.vcmHandle,
      text: channelMap.description,
      value: channelMap.vcmHandle,
      active,
    };

    if (active) {
      activeOption = option;
    }
    return option;
  });

  let activeOptionId = activeOption ? activeOption.value : null;

  return (
    <Card fluid>
      <Dropdown
        placeholder="Select a Channel Lineup"
        onChange={handleChannelMapChange}
        fluid
        selection
        options={channelMapOptions}
        value={activeOptionId}
      />
      <Card.Content>
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={<Button>View/Print Lineup</Button>}
          className="printable"
        >
          <Modal.Header>
            <Grid>
              <Grid.Row>
                <Grid.Column width="2">
                  <Image src={coxLogo} medium />
                </Grid.Column>
                <Grid.Column width="12" verticalAlign="bottom">
                  <Header textAlign="center">Channel List</Header>
                </Grid.Column>
                <Grid.Column width="2">
                  <Image src={logo || defaultLogo} floated="right" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Table compact="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell content="Channel Nr" />
                    <Table.HeaderCell content="Channel Channel Name" />
                    <Table.HeaderCell content="Channel Nr" />
                    <Table.HeaderCell content="Channel Channel Name" />
                  </Table.Row>
                </Table.Header>
                {renderChannels()}
              </Table>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Print"
              labelPosition="right"
              icon="print"
              onClick={() => {
                window.print();
                setOpen(false);
              }}
              color="blue"
            />
            <Button
              content="Cancel"
              labelPosition="right"
              icon="cancel"
              onClick={() => setOpen(false)}
              negative
            />
          </Modal.Actions>
        </Modal>
      </Card.Content>
    </Card>
  );
};

export default ChannelMapCard;
