import {useCallback, useContext, useEffect, useState} from "react";
import axios from "axios";
import {AuthContext} from "../contexts/auth-context";

const siteUrl = `${window.CONFIG_API_URL}`;

let axiosConfig;
const absoluteUrlRegEx = /^https?:\/\//i;

if (
  !siteUrl ||
  siteUrl === "undefined" ||
  siteUrl.length === 0 ||
  !absoluteUrlRegEx.test(siteUrl)
) {
  axiosConfig = {};
} else {
  axiosConfig = { baseURL: siteUrl };
}

const api = axios.create(axiosConfig);

export const types = {
  waiting: "waiting",
  loading: "loading",
  error: "error",
  success: "success",
};

export const initialState = {
  waiting: false,
  data: null,
  error: null,
  loading: false,
  successful: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.waiting:
      return {
        ...state,
        loading: false,
        data: null,
        error: null,
        successful: false,
      };
    case types.loading:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
        successful: false,
      };
    case types.success:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        successful: true,
      };
    case types.error:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
        successful: false,
      };
    default:
      return state;
  }
};

export const useSecureApi = (
  relativeUrl,
  method = "get",
  ready = true,
  dispatch,
  includeProperty = true
) => {
  const { client, logout, propertyId } = useContext(AuthContext);

  const [requestPayload, setRequestPayload] = useState(null);

  useEffect(() => {
    if (requestPayload) {
      const fetchData = async () => {
        if (!ready) {
          dispatch({ type: types.waiting });
          return;
        }
        dispatch({ type: types.loading });
        try {
          if (!client) {
            logout();
            return;
          }
          const token = await client.tokenManager.get("access_token");

          if (!token) {
            logout();
            return;
          }

          let url = siteUrl;

          if (includeProperty) {
            url = `${siteUrl}/${propertyId}`;
          }

          if (requestPayload.resource) {
            if (typeof relativeUrl === "function") {
              url = `${url}/${relativeUrl(requestPayload.resource)}`;
            } else {
              url = `${url}/${requestPayload.resource}`;
            }
          } else {
            url = `${url}/${relativeUrl}`;
          }

          const options = {
            url,
            method,
            headers: {
              Authorization: `Bearer ${token.accessToken}`,
            },
            data:
              requestPayload && requestPayload.data
                ? requestPayload.data
                : null,
          };

          const res = await api.request(options);
          if (res.data.results) {
            dispatch({ type: types.success, payload: res.data.results });
          } else {
            dispatch({ type: types.success, payload: res.data });
          }
        } catch (e) {
          if (
            e.response &&
            (e.response.status === 401 || e.response.status === 403)
          ) {
            console.log(e);
            logout();
          } else if (e.name === "OAuthError") {
            console.log(e);
            logout();
          }
          dispatch({ type: types.error, payload: e });
        }
      };
      fetchData();
    }
  }, [requestPayload]);

  const triggerFetch = useCallback(
    (data) => {
      if (data) {
        setRequestPayload(data);
      } else {
        setRequestPayload(Date.now());
      }
    },
    [setRequestPayload]
  );

  return [triggerFetch];
};
