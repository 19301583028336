import React from "react";
import {Segment} from "semantic-ui-react";
import RoomsTable from "./RoomsTable";
import RoomCard from "./RoomCard";

const RoomPage = () => {
  return (
    <Segment>
      <RoomCard />
      <RoomsTable />
    </Segment>
  );
};

export default RoomPage;
