import {useContext} from "react";
import {CmapContext} from "../contexts/cmap-context";

export const useChannelMaps = () => {
  const {
    fetchChannelMapsState,
    fetchChannelMaps,
    channelMapsState,
    selectChannelMap,
    deselectChannelMap,
  } = useContext(CmapContext);

  return {
    fetchChannelMapsState,
    fetchChannelMaps,
    channelMapsState,
    selectChannelMap,
    deselectChannelMap,
  };
};
