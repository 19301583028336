import React from "react";
import {Segment} from "semantic-ui-react";
import ChannelMapCard from "./ChannelMapCard";

const ChannelMapPage = () => {
  return (
    <Segment>
      <ChannelMapCard />
    </Segment>
  );
};

export default ChannelMapPage;
