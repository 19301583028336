import React from "react";
import {Header, Segment} from "semantic-ui-react";

export default () => {
  return (
    <Segment>
      <Header content="Help" icon={{ name: "help circle", color: "grey" }} />
      For technical support or assistance with your Contour for Hospitality
      solution, contact us at 1-877-840-2858.
    </Segment>
  );
};
