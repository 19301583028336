import React, {useContext, useEffect, useReducer} from "react";
import {initialState, reducer, useSecureApi,} from "../hooks/secure-api-hook-dispatch";

import {AuthContext} from "./auth-context";

const RoomContext = React.createContext(null);

export const types = {
  scheduleChanged: "schedule_changed",
  add: "add",
  deselect: "deselect",
  remove: "remove",
  select: "select",
  set: "set",
  unset: "unset",
  update: "update",
};

const scheduleReducer = (state, action) => {
  switch (action.type) {
    case types.scheduleChanged:
      return { ...state, resetTime: action.payload };
    default:
      return state;
  }
};

const roomsReducer = (state, action) => {
  switch (action.type) {
    case types.add:
      return { ...state, rooms: [action.payload, ...state.room] };
    case types.deselect:
      return { ...state, selectedRoom: null };
    case types.remove:
      return {
        ...state,
        rooms: state.rooms.filter((room) => room.id !== action.payload),
      };
    case types.select:
      return {
        ...state,
        selectedRoom: action.payload,
      };
    case types.set:
      return { ...state, rooms: [...action.payload] };
    case types.unset:
      return { ...state, rooms: [] };
    case types.update:
      // Update both the selectedRoom reset time, and the reset time in the rooms array.
      const resetTime = action.payload.resetTime;
      const roomId = state.selectedRoom.roomId;

      return {
        ...state,
        selectedRoom: { ...state.selectedRoom, resetTime },
        rooms: [
          { ...state.selectedRoom, resetTime },
          ...state.rooms.filter((room) => room.roomId !== roomId),
        ],
      };

    default:
      return state;
  }
};

const RoomProvider = ({ children }) => {
  const { authenticated } = useContext(AuthContext);
  // const [selectedRoom, setSelectedRoom] = useState(null);
  // const [resetTime, setResetTime] = useState(
  //   selectedRoom ? selectedRoom.resetTime : null
  // );

  const [fetchState, fetchDispatch] = useReducer(reducer, initialState);
  const [fetchRooms] = useSecureApi("rooms", "get", true, fetchDispatch);

  // const [{ data, error, loading }, fetchRooms] = useSecureApi("rooms");

  // const [scheduleState, scheduleDispatch] = useReducer(scheduleReducer, {
  //   ...selectedRoom,
  // });

  const [roomsState, roomsDispatch] = useReducer(roomsReducer, {
    rooms: [],
    selectedRoom: null,
  });

  const deselectRoom = () => roomsDispatch({ type: types.deselect });

  const selectRoom = (selectedRoom) =>
    roomsDispatch({ type: types.select, payload: selectedRoom });

  const setRooms = (rooms) =>
    roomsDispatch({ type: types.set, payload: rooms });

  const unsetRooms = () => roomsDispatch({ type: types.unset });

  const updateRoom = (resetTime) => {
    roomsDispatch({
      type: types.update,
      payload: { resetTime },
    });
  };

  const clearRoomReset = () => {
    roomsDispatch({
      type: types.update,
      payload: { resetTime: null },
    });
  };

  useEffect(() => {
    if (!authenticated) {
      unsetRooms();
      deselectRoom();
    }
  }, [authenticated]);

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  useEffect(() => {
    if (fetchState.data) {
      setRooms(fetchState.data.rooms);
    }
  }, [fetchState]);

  const defaultContext = {
    roomsState,
    setRooms,
    unsetRooms,
    selectRoom,
    deselectRoom,
    fetchState,
    updateRoom,
    clearRoomReset,
  };

  return (
    <RoomContext.Provider value={defaultContext}>
      {children}
    </RoomContext.Provider>
  );
};
export { RoomContext, RoomProvider };
