import {useContext, useReducer} from "react";

import {initialState, reducer, useSecureApi,} from "./secure-api-hook-dispatch";
import {RoomContext} from "../contexts/room-context";

export const useRoom = () => {
  const {
    selectRoom,
    deselectRoom,
    roomsState,
    setRooms,
    unsetRooms,
    updateRoom,
    clearRoomReset,
  } = useContext(RoomContext);

  const [unscheduleState, unscheduleDispatch] = useReducer(
    reducer,
    initialState
  );

  const ready = !!roomsState.selectedRoom;

  const roomId = ready ? roomsState.selectedRoom.roomId : "NOT_READY";

  if (!ready) {
    console.log("Room isn't ready yet, hold on...");
  }
  const [unscheduleRoom] = useSecureApi(
    `rooms/${roomId}/clearReset`,
    "delete",
    ready,
    unscheduleDispatch
  );

  const [fetchRoomsState, fetchRoomsDispatch] = useReducer(
    reducer,
    initialState
  );
  const [fetchRooms] = useSecureApi("rooms", "get", true, fetchRoomsDispatch);

  const [resetState, resetDispatch] = useReducer(reducer, initialState);

  const [resetRoom] = useSecureApi(
    `rooms/${roomId}/reset`,
    "post",
    ready,
    resetDispatch
  );

  const [rebootState, rebootDispatch] = useReducer(reducer, initialState);

  const [rebootRoom] = useSecureApi(
    `rooms/${roomId}/reboot`,
    "post",
    ready,
    rebootDispatch
  );

  const [rebootDeviceState, rebootDeviceDispatch] = useReducer(
    reducer,
    initialState
  );

  const [rebootDevice] = useSecureApi(
    (serial) => `rooms/${roomId}/serial/${serial}/reboot`,
    "post",
    ready,
    rebootDeviceDispatch
  );

  const [scheduleState, scheduleRoomDispatch] = useReducer(
    reducer,
    initialState
  );

  const [scheduleRoom] = useSecureApi(
    `rooms/${roomId}/scheduleReset`,
    "post",
    ready,
    scheduleRoomDispatch
  );

  return {
    unscheduleState,
    unscheduleRoom,
    resetState,
    resetRoom,
    rebootState,
    rebootRoom,
    scheduleState,
    scheduleRoom,
    fetchRoomsState,
    fetchRooms,
    selectRoom,
    deselectRoom,
    roomsState,
    setRooms,
    unsetRooms,
    updateRoom,
    clearRoomReset,
    rebootDevice,
    rebootDeviceState,
  };
};
