import React, {useContext} from "react";
import {Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {AuthContext} from "../contexts/auth-context";
import history from "../routing/history";

export default () => {
  const { admin } = useContext(AuthContext);
  return (
    <Menu>
      <Menu.Item
        name="rooms"
        as={Link}
        to="/rooms"
        content="Rooms"
        icon={{ name: "hotel", color: "grey" }}
        active={history.location.pathname === "/rooms"}
      />
      {/* <Menu.Item
        name="channelmaps"
        as={Link}
        to="/channelmaps"
        content="Channel Lineup"
        icon={{ name: "th list", color: "grey" }}
        active={history.location.pathname === "/channelmaps"}
      /> */}

      {admin && (
        <Menu.Item
          name="users"
          as={Link}
          to="/users"
          content="Users"
          icon={{ name: "user", color: "grey" }}
          active={history.location.pathname === "/users"}
        />
      )}
      {/* <Menu.Item
        name="health"
        as={Link}
        to="/health"
        content="Health &amp; Reporting"
        icon={{ name: "chart bar", color: "grey" }}
        active={history.location.pathname === "/health"}
      /> */}
      <Menu.Item
        name="help"
        as={Link}
        to="/help"
        content="Help"
        className="float right"
        icon={{ name: "help circle", color: "grey" }}
        active={history.location.pathname === "/help"}
      />
    </Menu>
  );
};
